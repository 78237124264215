.logo_footer_items {
	margin: 2rem 0 0;
	padding: 0;
}

.logo_footer_img {
	margin: 0;
	padding: 0;
	width: 12rem;
	height: 9rem;
}

@media (min-width: 40rem) {
	.logo_footer_items {
		margin: 3rem;
		padding: 0;
	}

	.logo_footer_img {
		margin: 0;
		padding: 0;
		width: 10rem;
		height: 7rem;
	}
}
