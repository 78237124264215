.logo_items {
	margin: 0;
	padding: 0;
}

.logo-item {
	margin: 0;
	padding: 0;
}

@media (min-width: 40rem) {
	.logo_items {
		margin: 2rem;
		padding: 0;
	}

	.logo-item {
		margin: 0;
		padding: 0;
		width: 6rem;
		height: 5rem;
	}
}
