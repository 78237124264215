.terms_bundle_items {
	margin: 2rem 0 5rem;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.paragraph_items {
	margin: 0 0 1rem;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.terms_date_item {
	margin: 0;
	padding: 0;
	color: var(--headerTextColor);
	font-family: var(--formFontFamillya);
	font-size: 0.7rem;
}

.terms_date_item_p {
	color: var(--headerTextColor);
	font-family: var(--formFontFamillya);
	font-size: 0.7rem;
}

.powerd_web_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.powerd_item {
	margin: 0 0.5rem;
	padding: 0;
	color: var(--headerTextColor);
	font-family: var(--formFontFamillya);
	font-size: 0.7rem;
}

.web_item {
	margin: 0;
	padding: 0;
	font-family: var(--formFontFamillya);
	font-size: 0.7rem;
	color: var(--webundleColor);
}

@media (min-width: 40rem) {
	.terms_bundle_items {
		margin: 3rem;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.paragraph_items {
		margin: 0 0 2rem;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.terms_date_item {
		margin: 0;
		padding: 0;
	}
}
