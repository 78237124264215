.section_video_items {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 40rem;
	position: sticky;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(
		var(--mainBackgroundLightS1) 0%,
		var(--mainBackgroundDarkS1) 100%
	);
}

.mainlogo_div_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.service_detailed_section {
	margin: 0;
	padding: 0;
	height: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.landscaping_over_years {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.servicess_items_main {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.special_services_items {
	margin: 0;
	padding: 0;
	height: auto;
}

.write_review_items {
	margin: 0;
	padding: 0;
	height: auto;
}

.home_footer_div {
	margin: 0;
	padding: 0;
	width: 100vw;
}

@media (min-width: 40rem) {
	.section_video_items {
		margin: 4rem 0 0 0;
		padding: 0;
		height: 40rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		background: linear-gradient(
			var(--mainBackgroundLightS1) 0%,
			var(--mainBackgroundDarkS1) 100%
		);
	}

	.mainlogo_div_items {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.service_detailed_section {
		margin: 0;
		padding: 0;
		font-family: var(--servicesCardFont);
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: linear-gradient(
			var(--mainBackgroundLightS1) 0%,
			var(--mainBackgroundDarkS1) 100%
		);
	}

	.landscaping_over_years {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.servicess_items_main {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.servicess_item {
		margin: 1rem 2rem 1rem;
	}

	.special_services_items {
		margin: 0;
		padding: 0;
	}

	.write_review_items {
		margin: 0;
		padding: 0;
	}

	.home_footer_div {
		margin: 0;
		padding: 0;
		width: 100vw;
	}
}
