.video_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: auto;
	font-size: 1rem;
	z-index: -4;
		background: linear-gradient(
			var(--mainBackgroundLightS1) 0%,
			var(--mainBackgroundDarkS1) 100%
		);
}

@media (min-width: 40rem) {
	.video_items {
		margin: 0;
		padding: 0;
			background: linear-gradient(
			var(--mainBackgroundLightS1) 0%,
			var(--mainBackgroundDarkS1) 100%
		);
	}
}
