.full_service_items {
	margin: 1rem 0 1rem 0;
	padding: 0;
	font-size: var(--fullserviceFontMobile);
	color: var(--fullserviceColor);
}

.full_service_item {
	opacity: 0;
}

@media (min-width: 40rem) {
	.full_service_item {
		margin: 1.5rem 0 0 0;
		padding: 0;
		font-size: var(--fullserviceFontDesktop);
	}
}
