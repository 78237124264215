.write_reviewss_items {
	margin: 2rem 1rem 1rem;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.satisfied_customer_img {
	margin: 0;
	padding: 0;
	display: flex;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	border-radius: 6px;
	object-fit: cover;
	overflow: hidden;
	width: 16rem;
	height: 10rem;
}

.review_button_items {
	margin: 3rem 0;
	padding: 0;
}

.review_button_main {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1.5rem;
	padding: 1rem 2rem;
	border: 1px solid var(--buttonBorder);
	background: var(--buttonBackground);
	color: var(--buttonTextColor);
	cursor: pointer;
	border-radius: 10px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	transition: all 0.4s;
}

.review_button_main:focus {
	outline: none;
}

.review_button_main:hover,
.review_button_main:active {
	background: var(--buttonHoverActive);
	border-color: var(--buttonHoverBorder);
	color: var(--buttonTextHoverColor);
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.36);
}

@media (min-width: 40rem) {
	.write_reviewss_items {
		margin: 2rem 1rem;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.satisfied_customer_img {
		margin: 0;
		padding: 0;
		display: flex;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		border-radius: 6px;
		object-fit: cover;
		overflow: hidden;
		width: 20rem;
		height: 12rem;
	}

	.review_button_items {
		margin: 0 2rem;
		padding: 0;
	}

	.review_button_main {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 1.5rem;
		padding: 1rem 2rem;
		border: 1px solid var(--buttonBorder);
		background: var(--buttonBackground);
		color: var(--buttonTextColor);
		cursor: pointer;
		border-radius: 10px;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		transition: all 0.4s;
	}

	.review_button_main:focus {
		outline: none;
	}

	.review_button_main:hover,
	.review_button_main:active {
		background: var(--buttonHoverActive);
		border-color: var(--buttonHoverBorder);
		color: var(--buttonTextHoverColor);
		box-shadow: 0 3px 12px rgba(0, 0, 0, 0.36);
	}
}
