.social_icon_items {
	margin: 2rem 0 1rem;
	padding: 0;
}

.icon_item_facebook {
	color: #1877f2;
}

@media (min-width: 40rem) {
	.social_icon_items {
		margin: 3rem;
		padding: 0;
	}

	a.icon_item_facebook {
		color: #3789f5;
	}

	a.icon_item_facebook:hover {
		color: #58a0ff;
	}
}
