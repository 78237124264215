.home_page_main_title_items {
	margin: 1rem;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title-item_text {
	margin: 0;
	padding: 0;
}

@media (min-width: 40rem) {
	.home_page_main_title_items {
		margin: 1rem 0 0 0;
		padding: 0;
	}
}
