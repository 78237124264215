.main_service_items,
.main_service_item_li {
	list-style: none;
	margin: 0.5rem 0;
	padding: 0;
}

.main_service_items {
	width: 20rem;
	display: flex;
	flex-direction: column;
	background: var(--servicesMainBase);
	padding: 0.6rem;
	border-radius: 12px;
}

.main_service_item_li {
	background-color: var(--servicesCardBase);
	border-radius: 10px;
	padding: 0.5rem 0;
	margin-bottom: 20px;
	overflow: hidden;
	font-family: var(--servicesCardFont);
	font-size: var(--serviceFontSizeOutside);
	cursor: pointer;
}

.main_service_item_li:last-child {
	margin-bottom: 0px;
	font-size: var(--serviceFontSizeInside);
}

@media (min-width: 40rem) {
	.services-items {
		margin: 0;
		padding: 0;
	}
}
