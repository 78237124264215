.form_main_div {
	margin: 0;
	padding: 0;
	height: auto;
}

.form_item_letvalues {
	width: 20rem;
	margin: 2rem auto;
	padding: 1rem;
	border-radius: 0.5rem;
	border: 0.1rem solid var(--borderShadowa);
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	background-color: var(--formBackgounda);
	font-family: var(--formFontFamillya);
}

.each_section_item_letvalues {
	margin: 0.5rem 0 1rem;
	padding: 0;
}
.label_item_letvalues {
	margin-bottom: 0.1rem;
	display: block;
}

.input_item_letvalues {
	width: 100%;
	display: block;
	box-sizing: border-box;
	font: inherit;
	border: 0.04rem solid var(--inputBordera);
	background-color: var(--inputBackground);
	border-radius: 0.2rem;
	padding: 0.2rem;
	color: black;
}

.input_item_letvalues:focus,
.textarea_item_letvalues:focus {
	background-color: var(--inputFocusa);
	border: 0.1rem solid var(--inputFocusBorder);
}

.button_item_letvalues {
	margin: 1rem 0;
	display: block;
	font: inherit;
	background-color: var(--buttonBackgrounda);
	color: var(--buttonTextColora);
	border: 0.05rem solid var(--buttonBorderColora);
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.46);
	padding: 0.5rem 1.5rem;
	border-radius: 0.3rem;
	font-size: 110%;
	transition: all 0.3s;
	cursor: pointer;
}

.button_item_letvalues:hover {
	background-color: var(--buttonHoverBackgrounda);
	border-color: var(--buttonHoverBordera);
	color: var(--buttonHoverColora);
}

.confirmation_fgm_items {
	margin: 0;
	padding: 0;
	background-color: var(--confirmationBackground);
}

.confirmation_fgm_item {
	margin: 0;
	color: var(--confirmationColor);
}

.formik_error {
	margin: 0;
	padding: 0;
	color: red;
}

@media (min-width: 40rem) {
	.form_items_letvalues {
		margin: 0;
		padding: 0;
	}
}
