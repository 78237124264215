.newlogo_items {
	margin: 0;
	padding: 0;
}

.newlogo_item {
	margin: 2rem 0;
	padding: 0;
	opacity: 0;
	width: 11rem;
	height: 8rem;
}

@media (min-width: 40rem) {
	.newlogo_items {
		margin: 0;
		padding: 0;
	}

	.newlogo_item {
		margin: 0.5rem;
		padding: 0;
		width: 20rem;
		height: 14rem;
	}
}
