.main_header {
	width: 100%;
	height: 4rem;
	position: fixed;
	display: flex;
	flex-direction: row-reverse;
	top: 0rem;
	left: 0;
	padding: 0;
	background: transparent;
	z-index: 3;
}

@media (min-width: 40rem) {
	.main_header {
		position: fixed;
		display: flex;
		flex-direction: row;
		margin-top: 0;
		padding: 0;
		left: 0;
	}
}
