.texttitle_items {
	margin: 0;
	padding: 0;
	height: auto;
}

.texttitle_item {
	margin: 0;
	padding: 2rem;
	opacity: 0;
	font-size: var(--ourStoryTitleFontSizeMobile);
}

@media (min-width: 40rem) {
	.texttitle_items {
		margin: 0;
		padding: 0;
		height: auto;
	}
	.texttitle_item {
		margin: 1rem 0 0 0;
		padding: 0.5rem;
		opacity: 0;
		color: var(--colorMainTitleContact);
		font-size: var(--ourStoryTitleFontSize);
	}
}
