.textcontent_items {
	margin: 0;
	padding: 1rem;
			height: auto;
	width: 100%;
}

.textcontent_item {
	margin: 0;
	padding: 0;
	opacity: 0;
	font-family: var(--formFontFamilly);
	font-size: var(--ourStoryTextFontSizeMobile);
	color: var(--ourStoryColorText);
	text-align: center;
}

@media (min-width: 40rem) {
	.textcontent_items {
		margin: 0.1rem 0;
		padding: 0.5rem;
				height: auto;
	}
	.textcontent_item {
		margin: 0;
		padding: 0;
		opacity: 0;
		font-family: var(--formFontFamilly);
		font-size: var(--ourStoryTextFontSize);
		text-align: center;
	}
}
