.main_service_item_li {
	list-style: none;
	margin: 0;
	padding: 0;
}

.main_service_item_li {
	border-radius: 10px;
	padding: 0;
	margin-bottom: 0.6rem;
	overflow: hidden;
	cursor: pointer;
}

.main_service_item_li:last-child {
	margin-bottom: 0px;
}

.outside_group_items {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title_item_outside {
	margin: 0 0 0.5rem;
	padding: 0;
	font-family: var(--serviceTitleFontOutside);
	font-size: var(--serrviceTitleFontSize);
	color: var(--serviceTiltleFontColor);
}

.icon_item_image {
	width: 8rem;
	height: 5rem;
	border-radius: 5px;
}

.tap_to_expand {
	margin: 1rem 0 0 0;
	padding: 0;
	font-size: var(--tapSize);
	font-weight: bolder;
	font-family: var(--tapFont);
	color:var(--tapToCloseExpand);
}

.group_inside_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title_item__inside {
	margin: 1rem;
	padding: 0;
	font-family: var(--serviceTitleFontOutside);
	font-size: var(--serrviceTitleInside);
	color: var(--serviceTiltleFontColor);
}

.div_image_item__inside {
	margin: 0;
	padding: 0;
}

.image_item__inside {
	width: 20rem;
	height: 18rem;
}

.content_item__inside {
	margin: 0;
	padding: 1rem;
	font-family: var(--);
	font-size: var(--serviceContentInsideFontSize);
	color: var(--serviceTiltleFontColor);
}

.taptocloseit_item__inside {
	margin: 0;
	padding: 0;
	font-size: var(--tapSize);
	font-family: var(--tapFont);
	color:var(--tapToCloseExpand);
}

@media (min-width: 40rem) {
	.services-items {
		margin: 0;
		padding: 0;
	}
}
