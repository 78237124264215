.title-item_text2 {
	margin:0.5rem 0;
	padding: 0;
	font-size: 1rem;
}

.proudly_item {
	margin: 0;
	padding: 0;
	color: var(--proudlyColor);
}

@media (min-width: 40rem) {
	.title-item_text2 {
		margin: 0;
		padding: 0;
		font-size: 1.5rem;
	}
	.proudly_item {
		margin: 0;
		padding: 0;
	}
}
