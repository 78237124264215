.loader_items {
	margin: 8rem 0 0 0;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loader_item_text {
	margin: 4rem;
	padding: 0;
	font-size: 1.5rem;
	color: var(--loaderText);
}

@media (min-width: 40rem) {
	.loader_items {
		margin: 5rem 0;
		padding: 0;
	}
}
