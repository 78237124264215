.ourstory_items {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(
		var(--mainBackgroundLightS1) 0%,
		var(--mainBackgroundDarkS1) 100%
	);
}

.ourstory_all_text {
	margin: 0;
	padding: 0;
	height: auto;
}

.ourstory_all_flowers {
	margin: 0;
	padding: 0;
	height: auto;
	display: flex;
	flex-direction: column;
}

@media (min-width: 40rem) {
	.ourstory_items {
		margin: 0;
		padding: 0;
		width: 100vw;
		height: 66rem;
		display: flex;
		flex-direction: column;
	}

	.ourstory_all_text {
		margin: 6rem 6rem 2rem 6rem;
		padding: 0;
	}

	.ourstory_all_flowers {
		margin: 0;
		padding: 0;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.main_flowers1 {
		margin: 1rem 10rem 1rem 0;
	}

	.main_flowers2 {
		margin: 4rem 0 0 10rem;
	}
}
