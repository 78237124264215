.nav-links_items_m {
	margin: 6rem 2rem;
	padding: 0;
	color: var(--headerTextColor);

}

.main_menu_list_items_m {
	list-style: none;
	margin: 0;
	padding: 0;
	height: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
		
}

.main_menu_list_item_m {
	margin: 2rem 0 0 0;

}

.main_menu_list_item_link_m {
	color: var(--textOnTheDrawer);
	text-decoration: none;
	font-size: 1.5rem;
}

@media (min-width: 40rem) {
	.nav-links_items_m {
		display: none;
	}
}
