.headertitle_items {
	margin: 0;
	padding: 0;
}

@media (min-width: 40rem) {
	.headertitle_items {
		margin: 0;
		padding: 0;
	}

	.headertitle_item {
		margin: 0;
		padding: 0;
		font-size: var(--headerFGMsize);
		font-family: inherit;
		color: var(--headerFGMcolor);
	}
}
