.thetruck_items {
	margin: 2rem 0 2rem;
	padding: 0;
}

.thetruck_item_ul {
	margin: 0 auto;
}

.thetruck_item_li {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	border-radius: 6px;
	overflow: hidden;
	opacity: 0;
}

.thetruck_item_img {
	opacity: 1;
	width: 17rem;
}

@media (min-width: 40rem) {
	.thetruck_items {
		margin: 1rem 0 3rem;
		padding: 0;
		height: auto;
	}

	.thetruck_item_ul {
		margin: 0 auto;
	}

	.thetruck_item_li {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		border-radius: 6px;
		overflow: hidden;
		opacity: 0;
		width: 17rem;
		height: 12rem;
	}

	.thetruck_items_img {
		margin: 0;
		height: 11rem;
		width: 100%;
		object-fit: cover;
	}
}
