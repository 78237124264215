.landscapeservices_items {
	margin: 2rem;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
		font-family: var(--mainFont);
		font-size: 1.6rem;
		color: var(--landscapeServicesColor);
}

@media (min-width: 40rem) {
	.landscapeservices_items {
		margin: 2rem;
		padding: 0;
	 	height: auto;
		font-family: var(--mainFont);
		font-size: var(--landscapeServicesFontSize);
		color: var(--landscapeServicesColor);
	}
}
