.locations_items {
	margin: 0 -2rem;
	padding: 0;
	width: auto;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: flex;
}

@media (min-width: 40rem) {
	.locations_items {
		margin: 0;
		padding: 0;
		color: white;
		font-size: 1rem;

	}
}
