.overtwenty_years_items {
	margin: 2rem 0 0;
	padding: 0;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	border-radius: 6px;
	text-align: center;
	overflow: hidden;
	width: 19rem;
	height: 6rem;
	background-color: #f4ff81;
}

.overtwenty_years_item {
	margin: 0;
	padding: 0;
	font-family: var(--mainFont);
	font-size: 1.4rem;
	color: #263238;
}

@media (min-width: 40rem) {
	.overtwenty_years_items {
		margin: 2rem 0 0;
		padding: 0;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		border-radius: 6px;
		text-align: center;
		overflow: hidden;
		width: 30rem;
		height: 4rem;
		background-color: #f4ff81;
	}

	.overtwenty_years_item {
		margin: 0;
		padding: 0;
		font-family: var(--mainFont);
		font-size: 1.4rem;
		color: #263238;
	}
}
