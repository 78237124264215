.top-card {
	margin-top: 0;
	padding: 0;
	width: 100%;
	height: 40rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 6px;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.45);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	z-index: 5;
}

@media (min-width: 40rem) {
	.top-card {
		margin: 0;
		padding: 1rem;
		width: 100%;
		height: 40rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
