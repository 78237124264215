@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

:root {
	/* primary */
	--primary-0: none;
	--primary-1: none;

	/* box shadows, modals, and backdrops */
	--shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
	--backdrop: rgba(0, 0, 0, 0.75);

	/* Main App */
	--mainBackground: #dcedc8;

	/* Services Sections */

	--mainBackgroundLightS1: #fff;
	--mainBackgroundDarkS1: #43a047;

	/* Loader */
	--loaderText: #dd2c00;
	--loaderSpinner: #c6ff00;

	/* Header */
	--headerColor: #455a64;
	--headerTextColor: #eceff1;
	--headerLinksFontSize: 1.4rem;
	--headerLinksHoverColor: #eceff1;
	--headerFGMsize: 2rem;
	--headerFGMcolor: #76ff03;

	/* SIDE DRAWER */
	--sideDrower: #082e1066;
	--textOnTheDrawer: #ffcc80;

	/* MENU Burgger */
	--menubuttonfont: #fafafa;
	--buttonborder: #212121;
	--buttonbackground: #ffcc80;
	--buttonMenuTextColor: black;

	/* Mobile View */
	--mobileFGMFontSize: 1.6rem;
	--fullserviceColor: #fafafa;
	--fullserviceFontMobile: 0.9rem;

	/* Section 1 - Titles */
	--desktopFGMFontSize: 4rem;
	--fullserviceFontDesktop: 3rem;
	--fgmMainTitle: #76ff03;
	--proudlyColor: #ffff00;

	/* Landscape Services */

	--landscapeServicesFontSize: 2rem;
	--landscapeServicesColor: #1b5e20;

	/* Section 2 - Services */
	--tapToCloseExpand: #ef6c00;
	--servicesMainBase: #558b2f;
	--servicesCardBase: #f9fbe7;
	--servicesCardFont: 'Roboto Mono', monospace;
	--serviceTitleFontOutside: 'Antonio', sans-serif;
	--serviceTiltleFontColor: #3e2723;
	--serrviceTitleFontSize: 2rem;
	--tapColor: #263238;
	--tapSize: 1rem;
	--tapFont: 'Roboto Mono', monospace;
	--serrviceTitleInside: 1.5rem;
	--serviceContentInsideFontSize: 1rem;

	/* Our Story Desktop*/

	--ourStoryTitleFontSize: 3rem;
	--ourStoryTextFontSize: 1.2rem;

	/* Our Story Mobile*/
	--ourStoryColorText: #212121;
	--ourStoryTitleFontSizeMobile: 3rem;
	--ourStoryTextFontSizeMobile: 1.2rem;

	/* Contact Page */
	--colorMainTitleContact: #1b5e20;
	--colorAnimeInfo: #f57c00;
	--phoneColor: #e65100;

	/* Contact Form */
	--formFontFamillya: 'Roboto Mono', monospace;
	--formBackgounda:#b0bec5;
	--borderShadowa: #37474f;
	--inputBordera: #37474f;
	--inputTextColora: black;
	--inputBackground: #f5f5f5;
	--inputFocusa: #fffde7;
	--inputFocusBorder: #ffe57f;
	--buttonBackgrounda: #ffb74d;
	--buttonTextColora: #757575;
	--buttonBorderColora: #263238;
	--buttonHoverBackgrounda: #f57c00;
	--buttonHoverBordera: #eceff1;
	--buttonHoverColora: #eceff1;

	/* Confirmation colors*/
	--confirmationBackground: #ccff90;
	--confirmationColor: #004d40;

	/* Write review button */

	--buttonBackground: #ffab40;
	--buttonBorder: #616161;
	--buttonTextColor: #616161;
	--buttonHoverActive: #ff9100;
	--buttonHoverBorder: #212121;
	--buttonTextHoverColor: #212121;

	/* Footer */
	--footerColor: #263238;
	--webundleColor: #f57c00;

	/* Fonts */
	--mainFont: 'Antonio', sans-serif;
	--menubuttonfont: 'Roboto Mono', monospace;
	--extraSmallText: 0.7em;
	--smallText: 0.8em;
	--borderRadius: 0.25rem;
	--transition: 0.3s ease-in-out all;
	--defaultFontSize: 100%;
}

/* Main App */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	margin: 0;
	padding: 0;
	font-family: var(--mainFont);
	background-color: var(--mainBackground);
	font-size: var(--defaultFontSize);
}

body {
	margin: 0;
	padding: 0;
}
.slide-in-left-enter {
	transform: translateY(-100%);
}

.slide-in-left-enter-active {
	transform: translateY(0);
	opacity: 1;
	transition: all 400ms;
}

.slide-in-left-exit {
	transform: translateY(0%);
	opacity: 1;
}

.slide-in-left-exit-active {
	transform: translateY(-60%);
	opacity: 0;
	transition: all 200ms;
}

.center {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
