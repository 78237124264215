.logo_contact_items {
	margin: 2rem 0 2rem;
	padding: 0;
}

.logo_contact_item_ul {
	margin: 0 auto;
}

.logo_contact_item_li {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	border-radius: 6px;
	overflow: hidden;
	opacity: 0;
}

.logo_contact__img {
	opacity: 1;
	width: 17rem;
}

@media (min-width: 40rem) {
	.logo_contact_items {
		margin: 1rem 4rem;
		padding: 0;
		height: auto;
	}

	.logo_contact_item_ul {
		margin: 0 auto;
	}

	.logo_contact_item_li {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		border-radius: 6px;
		overflow: hidden;
		opacity: 0;
		width: 17rem;
		height: 11rem;
	}

	.logo_contact__img {
		margin: 0;
		height: 11rem;
		width: 100%;
		object-fit: cover;
	}
}
