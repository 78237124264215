.mainloclogo_items {
	margin: 0;
	padding: 0;
	width: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main_loc_proud_item {
	opacity: 0;
	margin: 0;
	padding: 0;
}

.new_logo_item {
	margin: 0;
	padding: 0;
}

@media (min-width: 40rem) {
	.mainloclogo_items {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.main_loc_proud_item {
		margin: 0 4rem;
		padding: 0;
	}

	.new_logo_item {
		margin: 1rem 2rem;
		padding: 0;
	}
}
