.contact_items {
	margin: 0;
	padding: 2rem 0;
	width: 100vw;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	background: linear-gradient(
		var(--mainBackgroundLightS1) 0%,
		var(--mainBackgroundDarkS1) 100%
	);
}

.contact_items_all {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.contact_info_text {
	margin: 4rem 0 0.5rem;
	padding: 0;
	font-family: inherit;
	font-size: 1.6rem;
	color: var(--colorMainTitleContact);
}

	.contact_info_text_fgm {
		margin: 0.5rem 0 0.5rem 0;
		padding: 0;
		font-size: 1rem;
		color: var(--colorMainTitleContact);
	}


.form_truck {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.free_truck_img_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form_photo_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (min-width: 40rem) {
	.contact_items {
		margin: 4rem 0 0 0;
		padding: 0;
		width: 100vw;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.contact_info_text {
		margin: 4rem 0 0.5rem 0;
		padding: 0;
		font-size: 3.5rem;
		color: var(--colorMainTitleContact);
	}

	.contact_info_text_fgm {
		margin: 1rem 0 2rem 0;
		padding: 0;
		font-size: 3rem;
		color: var(--colorMainTitleContact);
	}

	.form_truck {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.free_truck_img_items {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.form_photo_items {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
