.main-navigation__nav {
	display: none;
}

.main-navigation__menu_button {
	margin: 0 2rem;
	padding: 0;
}

.main-navigation__menu-btn {
	width: 3rem;
	height: 2rem;
	background: transparent;
	border: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	margin-right: 1rem;
	cursor: pointer;
}

.main-navigation__drawer-nav {
	display: block;
	margin-left: 6rem;
	align-items: center;
	justify-content: space-around;
	height: 100%;
}

.button_menu_items {
	margin: 1rem 0.5rem;
	padding: 0;
}

.button_menu_item {
	margin: 1rem 1rem;
	padding: 0 0.2rem;
	height: 2rem;
	width: 5rem;
	font-size: 1rem;
	font-family: var(--menubuttonfont);
	border-color: var(--buttonborder);
	background-color: var(--buttonbackground);
	color: var(--buttonMenuTextColor);
	cursor: pointer;
}

@media (min-width: 40rem) {
	.button_menu_item {
		display: none;
	}
	.main-navigation__drawer-nav {
		display: none;
	}
	.main-navigation__menu-btn {
		display: none;
	}

	.main-navigation__nav {
		width: 100%;
		height: 6rem;
		margin: 0;
		padding: 0;
		background: var(--headerColor);
		position: fixed;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

	}
}
