.alltext_items {
	margin: 0;
	padding: 0;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.text_item {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

@media (min-width: 40rem) {
	.alltext_items {
		margin: 0;
		padding: 0;
		width: 40rem;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}
