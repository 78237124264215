.fgm_items {
	margin: 5rem 0 1rem 0;
	padding: 0;
	color: white;

}

.fgm_items_text {
	opacity: 1;
	font-size: var(--mobileFGMFontSize);
}

@media (min-width: 40rem) {
	.fgm_items {
		margin: 1rem 0;
		padding: 0;
	}

	.fgm_items_text {
		opacity: 1;
		font-size: var(--desktopFGMFontSize);
	}
}
