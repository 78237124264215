.call_text_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.call_text_item {
	margin: 0 0 2rem;
	padding: 0;
	font-family: var(--formFontFamillya);
	font-size: 1.5rem;
}

.motion_phone_mobile_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

@media (min-width: 40rem) {
	.call_text_items {
		display: none;
	}
	.motion_phone_mobile_items {
		display: none;
	}
}
