.animetext_items {
	margin: 3rem 2rem;
	padding: 0;
	width: 16rem;
	align-items: center;
}

.animetext_items_ul {
	margin: 0 auto;
}

.animetext_item_li {
	list-style: none;
	margin: 0;
	padding: 0;
	height: 6rem;
	width: 16rem;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	border-radius: 6px;
	overflow: hidden;
	background-color: #ffa726;
	opacity: 0;
}

.animetext_info {
	margin: 0;
	padding: 0.5rem;
	font-size: 0.8rem;
	font-family: var(--formFontFamillya);
	color: black;
	text-align: center;
}

@media (min-width: 40rem) {
	.animetext_items {
		margin: 0;
		padding: 0;
		width: 60%;
	}

	.animetext_items_ul {
		margin: 0 auto;
	}

	.animetext_item_li {
		list-style: none;
		margin: 0;
		padding: 0.5rem;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		border-radius: 6px;
		overflow: hidden;
		text-align: center;
		opacity: 0;
		width: 26rem;
		height: 6rem;
	}

	.animetext_info {
		margin: 0;
		padding: 0.5rem;
		font-size: 1rem;
	}
}
