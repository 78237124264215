.main_menu_list_items {
	display: none;
}

@media (min-width: 40rem) {
	.nav-links_items {
		display: block;
		margin: 0 6rem 0 0;
		padding: 0;
		background: transparent;
	}

	.main_menu_list_items {
		list-style: none;
		margin: 0;
		padding: 0;
		height: 50%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.main_menu_list_item {
		margin: 0 1rem;
	}

	.main_menu_list_item_link {
		margin: 0 0.5rem;
		padding: 0;
		position: relative;
		text-decoration: none;
		font-size: var(--headerLinksFontSize);
		color: var(--headerTextColor);
		cursor: pointer;
	}

	.main_menu_list_item_link::after {
		content: '';
		position: absolute;
		background-color: var(--headerLinksHoverColor);
		height: 3px;
		width: 0;
		left: 0;
		bottom: -10px;
		transition: 0.3s;
	}

	.main_menu_list_item_link:hover:after {
		width: 100%;
	}
}
