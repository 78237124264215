.special_images_items {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.special_images_individual_item {
	margin: 1rem 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.special_title_item {
	margin: 0;
	padding: 0;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	border-radius: 6px;
	text-align: center;
	overflow: hidden;
	width: auto;
	height: 3rem;
	background-color: #ffb74d;
}

.s_title_text {
	margin: 0 0.5rem 0;
	padding: 0;
	text-align: center;
	font-family: var(--serviceTitleFontOutside);
	font-size: var(--serrviceTitleFontSize);
}

.special_content_item {
	margin: 0.5rem 0 0.5rem;
	padding: 0;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	border-radius: 6px;
	text-align: center;
	overflow: hidden;
	width: 20rem;
	height: auto;
	background-color: #ffd54f;
}

.s_content_text {
	margin: 0 0.5rem 0;
	padding: 0;
	height: auto;
	text-align: center;
	font-family: var(--formFontFamillya);
	font-size: 1.2rem;
}

.special_image_item_div {
	margin: 1rem 0 0.5rem;
	padding: 0;
}

.special_image_item_image {
	margin: 0;
	padding: 0;
	display: flex;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
	border-radius: 6px;
	object-fit: cover;
	overflow: hidden;
	width: 20rem;
	height: 14rem;
}

@media (min-width: 40rem) {
	.special_images_items {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.special_images_individual_item {
		margin: 1rem 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.special_title_item {
		margin: 0;
		padding: 0;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		border-radius: 6px;
		text-align: center;
		overflow: hidden;
		width: auto;
		height: 3rem;
		background-color: #ffb74d;
	}

	.s_title_text {
		margin: 0 0.5rem 0;
		text-align: center;
		font-family: var(--serviceTitleFontOutside);
		font-size: var(--serrviceTitleFontSize);
	}

	.special_content_item {
		margin: 0.5rem 0 0.5rem;
		padding: 0;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		border-radius: 6px;
		text-align: center;
		overflow: hidden;
		width: 26rem;
		height: auto;
		background-color: #ffd54f;
	}

	.s_content_text {
		margin: 0 0.5rem 0;
		text-align: center;
		font-family: var(--formFontFamillya);
		font-size: 1.2rem;
	}

	.special_image_item_div {
		margin: 0.5rem 0 0.5rem;
		padding: 0;
	}

	.special_image_item_image {
		margin: 0;
		padding: 0;
		display: flex;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
		border-radius: 6px;
		object-fit: cover;
		overflow: hidden;
		width: 26rem;
		height: 16rem;
	}
}
