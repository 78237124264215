.footer_items_mobile {
	margin: 0;
	padding: 0;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	background-color: var(--footerColor);
	z-index: 5;
}

.footer_items_desktop {
	display: none;
}

@media (min-width: 40rem) {
	.footer_items_mobile {
		display: none;
	}
	.footer_items_desktop {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: var(--footerColor);
		z-index: 5;
	}

	.footer_items_desktop_column {
		margin: 0;
		padding: 1rem 3rem;
		height: 8rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: var(--footerColor);
	}
}
