.header_phone_items {
	margin: 0;
	padding: 0;
}

@media (min-width: 40rem) {
	.header_phone_items {
		margin: 0;
		padding: 0;
		width: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.header_phone_item {
		margin: 0 0 0 1rem;
		padding: 0;
				width: 8rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.header_phone_call_today {
		margin: 0.2rem;
    color: #ffc400;
		font-size: 1.3rem;

	}

	.header_phone_number {
    margin: 0.2rem;
		color: var(--headerTextColor);
	}
}
