.fgmbottom_items {
	margin: 0;
	padding: 0;
}

.fgmbottom_item {
	margin: 0;
	padding: 0;
	opacity: 0;
	font-size: 1.2rem;
	color: var(--fgmMainTitle);
}

@media (min-width: 40rem) {
	.fgmbottom_items {
		margin: 0;
		padding: 0;
	}

	.fgmbottom_item {
		margin: 0;
		padding: 0 0 0.5rem 0;
		font-size: 2.5rem;
	}
}
