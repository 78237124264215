.side-drawer {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;
	height: 50vh;
	width: 70%;
	background: var(--sideDrower);
  opacity: 1;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
