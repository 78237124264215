.icon_phone_items {
	margin: 0 0 0 5rem;
	padding: 0;
}

.phone_color {
	font-size: 2rem;
	color: var(--phoneColor);
}

@media (min-width: 40rem) {
	.icon_phone_items {
		display: none;
	}
}
